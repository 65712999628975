
import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';

const { fields } = UserModel;

const formSchema = new FormSchema([
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.avatarsProfile,
]);

export default {
  name: 'app-profile-form-page',

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      currentUser: 'auth/currentUser',
      saveLoading: 'auth/loadingUpdateProfile',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    this.model = formSchema.initialValues(this.currentUser);
  },

  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile',
    }),

    doReset() {
      this.model = formSchema.initialValues(
        this.currentUser,
      );
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const values = formSchema.cast(this.model);
      this.doUpdateProfile(values);
    },
  },
};
